import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/core/ngrx/app.state";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { HttpService } from "./http.service";
import {MESSAGE_SET} from "../ngrx/store/action/message-set.action";
import {LOADER_SHOW} from "../ngrx/store/action/loader.action";


@Injectable()
export abstract class BaseService<T extends object> {

    public url: string = "";
    public urlPrepared: string = "";
    protected _model?:T;
    private IsShowLoding: boolean = true;

    constructor(
        protected _http: HttpService,
        protected _store: Store<AppState>,
        protected _isAuthService:AuthService) {
    }

    public create<T>(c: {new(): T; }): T {
        return new c();
    }

    public execute(obj:T | any, isShowLoding: boolean = true):void {
        this.IsShowLoding = isShowLoding;

        this._http.setApiUrl( environment.baseUrl );
        this.urlPrepared = this.url;
        if(this.isNeedPrepareUrl())
        {
            this._model = obj;
            this.prepareUrl();
        }
    };

    private prepareUrl() {
        if (this._model != undefined) {
            for (let [element, value] of Object.entries(this._model)) {
                let pattern = '{' + element + '}';
                if(this.urlPrepared && this.urlPrepared.indexOf(pattern) > 0) {
                    this.urlPrepared = this.urlPrepared.replace(pattern, value);
                }
            }
        }
    }

    private getModelProperties(obj: any):string[] {
        let keys = [];
        for(let key in obj){
           keys.push(key);
        }
        return keys;
    }

    private isNeedPrepareUrl():boolean {
        return this.url.indexOf('{') > 0;
    }

    protected get(obj: T | any) {
        return this._http.get(this.urlPrepared, obj);
    }

    protected put(obj: T | any) {
        return this._http.put(this.urlPrepared, obj);
    }

    protected post(obj: T | any) {
        return this._http.post(this.urlPrepared, obj);
    }

    protected delete(obj: T | any) {
        return this._http.delete(this.urlPrepared, obj);
    }

    public responceHandler(response:Response, action:string, payload:any) {
        if(response.ok) {
            if(payload == undefined) return;
            //if(payload.hasOwnProperty('Version')) payload.Version++;
            this._store.dispatch({type:action, payload});
        }
        else
        {
            let message = ""
            response.text().then(r => message = r);
            console.log("message");
            console.log(message);
            console.log("message");
            this._store.dispatch(MESSAGE_SET({message}));
        }
    }

}
