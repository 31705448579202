import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {PasswordResetParam, PasswordResetService} from "../../core/ngrx/services/password-reset/password-reset.service";
import {ResetToken, UserByResetGetService} from "../../core/ngrx/services/user/user-by-reset-get.service";
import {ChangeUserParams, ChangeUserService} from "../../core/ngrx/services/user/change-user.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {MESSAGE_SET} from "../../core/ngrx/store/action/message-set.action";
import {Subscription} from "rxjs";


@Component({
  selector: 'password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [PasswordResetService, UserByResetGetService, ChangeUserService]
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  checkoutForm: any;
  registerView: any;
  token: string = "";
  emailCheckoutForm: any;
  showForm: boolean = false;
  private _sub1: Subscription | undefined;




  constructor(
    private _store: Store<AppState>,
    private _formBuilder: FormBuilder,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute,
    private _resetService: PasswordResetService,
    private _userByReset: UserByResetGetService,
    private _changeUser: ChangeUserService,
    private _router: Router) {
    this.checkoutForm = this._formBuilder.group({
      password: new FormControl("", [Validators.required, Validators.minLength(5)]),
    });
    this.emailCheckoutForm = this._formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email])
    });
    this._sub1 = _activatedRoute.params.subscribe(p => {
      this.token = p['token'];
      // console.log(this.token)
    });
  }

  ngOnDestroy(): void {
    this._sub1?.unsubscribe();
  }

  ngOnInit(): void {
    this.showForm = this.token != undefined;
    // console.log(window.location)
    this._titleService.setTitle("Сброс пароля < Betatrader.ru");
  }

  onSubmit(value: any) {
    if (!this.showForm) {
      this._resetService.execute(new PasswordResetParam(value.email)).subscribe((res: any) => {
        if (res.result) {
          this._store.dispatch(MESSAGE_SET({message: "Ссылка для восстановления пароля отправлена вам на почту."}))
        } else {
          this._store.dispatch(MESSAGE_SET({message: res.message}))
        }
      })
    } else {
      this._changeUser.execute(new ChangeUserParams(this.token, 10, this.checkoutForm.controls['password'].value)).subscribe(r => {
        if (r.result) {
          this._store.dispatch(MESSAGE_SET({message: "Пароль успешно установлен"}));
          this._router.navigate(['/login']).then();
        } else {
          this._store.dispatch(MESSAGE_SET({message: r.message}))
        }
      })
    }
  }
}


